.LoadingContainer {
    position: absolute;
    background-color: rgb(0,0,0,0.6);
    z-index: 100;
    top: 0px;
    right:0px;
    bottom:0px;
    left:0px;
    overflow: hidden !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LoadingTitle {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: #FF5C5C;
}