.PhoneMenuContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    margin-right: 5px;
}

.PhoneMenuIcon:active {
    color: grey;
}

.PhoneMenu_categoriesContainer {
    border: 1px solid rgb(211, 201, 193);
    border-top: none;
    background-color: rgb(248, 243, 239);
    padding-bottom: 20px;
    padding-top: 10px;
    border-radius: 0px 0px 0px 12px;
    position: absolute;
    top: 50px;
    display: flex;
    justify-content: flex-end;
    z-index: 3;
}

.PhoneMenu_show {
    display: flex;
}

.PhoneMenu_hide {
    display: none;
}

.PhoneMenuHamburgerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.PhoneMenuHamburgerContainer span {
    font-size: 10px;
    text-align: center;
    align-self: center;
    margin-top: -5px;
}