.SearchBarOuterContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.SearchBarContainer {
    display: flex;
    justify-content: flex-end;
}

.SearchBarInput {
    width: 100%;
    height: 30px;
    font-size: 16px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 12px;
    border-color: black;
    border-width: 0.5px;

    @media (max-width: 768px) {
        font-size: 12px;
        padding-left: 5px;
     }
}

.SearchBarIconContainer {
    position: absolute;
    margin-left: 10px;
    margin-top: 2px;

    @media (max-width: 768px) {
        margin-top: 5px;
     }
}

.SearchBarInput:focus {
    outline: none !important;
    border: 0.5px solid red;
    box-shadow: 0 0 2px #ff7c1f;
}

.SearchBarResultsContainer {
    position: absolute;
    background-color: white;
    width: 50%;
    right: 1px;
    padding-bottom: 6px;
    top: 34px;
    border-radius: 0px 0px 12px 12px;
    z-index: 10;

    @media (max-width: 768px) {
        width: 70%;
     }
}

.SearchBarSearchResult {
    display: flex;
    justify-content: flex-start;
    border: 0.5px solid rgb(58, 58, 58, 0.8);
    border-radius: 8px;
    margin-bottom: 4px;
    cursor: pointer;
}

.SearchBarSearchResult img {
    width: 80px;
    height: 80px;

    @media (max-width: 768px) {
        width: 60px;
        height: 60px;
     }
}

.SearchBarSearchResultDetails {
    align-self: center;
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 8px;

    @media (max-width: 768px) {
        margin-right: 5px;
     }
}

.SearchBarResultsShowMoreContainer {
    display: flex;
    justify-content: center;
}

.SearchBarResultsShowMoreContainer span {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

.SearchBarResultsNoResultsContainer {
    display: flex;
    justify-content: center;
}

.SearchBarResultsNoResultsContainer span {
    font-size: 14px;
    font-weight: 500;
}

.SearchBarResultsContainer a {
    text-decoration: none;
    color: black;
}

.SearchBarSearchResultsTitle {
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 768px) {
        font-size: 14px;
     }
}

.SearchBarSearchResultsPrice {
    font-size: 14px;

    @media (max-width: 768px) {
        font-size: 12px;
     }
}