.HeaderOuterContainer {
    position: relative;
}

.HeaderMessageContainer {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 768px) {
        
    }
}

.HeaderMessageContainer span {
    font-weight: 700;
    font-size: 14px;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.HeaderMessageContainer img {
    width: 40px;
    height: 15px;
    margin-top: 2px;
    margin-right: 5px;

    @media (max-width: 768px) {
        width: 30px;
        height: 10px;
        margin-top: -1px;
    }
}

.HeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.HeaderIcon {
    
    margin-right: 20px;
    flex: 1;

    @media (max-width: 768px) {
        margin-right: 0;
    }
}

.HeaderIcon img {
    width: 100px;
    height: auto;

    @media (max-width: 768px) {
        width: 20vw;
    }
}

.HeaderDesktopIcon {
    display: flex;
    justify-content: flex-end;
    
    @media (max-width: 768px) {
        display: none;
    }
}

.HeaderPhoneIcon {
    display: none;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 8px;
        margin-bottom: 5px;
    }
}

.HeaderPhoneIconContainer {
    display: none;

    @media (max-width: 768px) {
        display: flex;
        margin-top: 2px;
        justify-content: center;
        flex-direction: row;
    }
}

.HeaderSearchBar {
    flex: 7;
    align-self: center;
    text-align: end;
    direction: rtl;
    display: flex;
    justify-content: flex-end;
    max-width: 600px;

    @media (max-width: 768px) {
       margin-left: 10px;
       margin-right: 10px;
    }
}

.HeaderFiller {
    flex: 3;

    @media (max-width: 768px) {
        display: none;
    }
}

.HeaderPhoneMenuContainer {
    display: none;
    
    @media (max-width: 768px) {
        display: flex;
    }
}