.CategoryContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 40px;
    height: 90%;
    overflow-y: scroll;
}

.CategoryProductContainer {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

.CategoryPathContainer {
    margin-right: 50px;
    margin-bottom: -20px;
    font-size: 14px;

    @media (max-width: 768px) {
        margin-right: 12px;
        margin-top: 12px;
        margin-bottom: -24px;
        font-size: 10px;
    }
}