.ProductDetailsContainer {
    display: flex;
    flex-direction: row;
    padding-top: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.ProductDetailsImagesContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    
    @media (max-width: 768px) {
        justify-content: center;
        padding-left: 10px;
    }
}

.ProductDetailsImage {
    width: 20vw;
    height: 20vw;
    margin-right: 20px;
    margin-bottom: 10px;
    
    @media (max-width: 768px) {
        width: 43vw;
        height: 43vw;
        margin-left: 10px;
        margin-right: 10px;
    }

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.ProductDetailsTextDetailsContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 40px;

    @media (max-width: 768px) {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 30px;
    }
}

.ProductDetailsTitleContainer {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1px;

    @media (max-width: 768px) {
        font-size: 22px;
        letter-spacing: 0.2px;
        margin-right: 15px;
        margin-top: 20px;
    }
}

.ProductDetailsDescriptionContainer {
    text-align: right;
    margin-top: 10px;
    font-size: 20px;

    @media (max-width: 768px) {
        font-size: 16px;
        margin-right: 15px;
        margin-left: 10px;
    }
}

.ProductDetailsPriceContainer {
    margin-top: 40px;
    font-size: 24px;

    @media (max-width: 768px) {
        font-size: 18px;
        font-weight: 500;
        margin-top: 20px;
        margin-right: 15px;
    }
}

.ProductDetailsButtons {
    margin-top: 40px;
    width: 400px;

    @media (max-width: 768px) {
        margin-top: 20px;
        width: 80%;
        align-self: center;
    }
}

.ProductDetailsCategoriesContainer {
    display: none;

    @media (max-width: 768px) {
        display: flex;
        font-size: 12px;
        align-items: center;
    }
}

.ProductDetailsCategoriesContainer span {
    margin-right: 3px;
}