
.AppHeader {
}

.AppBody {
    display: flex;
    flex-direction: row;  
    height: 100%;
    
    @media (max-width: 768px) {
        height: 100%;  
    }
}

.AppContentContainer {
    flex: 9;

    @media (max-width: 768px) {
        height: 100%;
    }
}

.AppMenuContainer {
    flex: 1;
    margin-top: 25px;
    border: 0.2px solid rgb(0, 0, 0, 0.2);
    border-bottom: none;
    border-top: none;

    @media (max-width: 768px) {
        display: none;
    }
}