.BuyNowButton {
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 600;
    background: linear-gradient(180deg, #fd9393, #f99c9c);
    border-radius: 8px;
    width: 100%;
    height: 50px;
    cursor: pointer;
    border: none;
    color: black;
    @media (max-width: 768px) {
        font-size: 100%;
        letter-spacing: 0.2px;
    }
}

.BuyNowButton:hover {
    background: linear-gradient(180deg, #f64d4d, #f96363);
}

.BuyNowButton:active {
    background: linear-gradient(180deg, #f68b8b, #f96363);
}

.BuyNowButtonSmall {
    height: 30px;
    font-size: 12px;
    
    @media (max-width: 768px) {
        height: 25px;
        font-size: 8px;
    }
}