.CategoryItemContainer {
    cursor: pointer;
    width: 100%;

    @media (max-width: 768px) {
        margin-top: 5px;
    }
}

.CategoryItemInnerContainer {
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: -28px;
    white-space: nowrap;

    @media (max-width: 768px) {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.CategoryItemInnerContainer p {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);

    @media (max-width: 768px) {
        font-size: 15px;
        font-weight: 500;
    }
}

.CategoryItemInnerContainer p:hover {
    text-decoration: underline;
}

.CategoryItemInnerContainer p:active {
    color: var(--active-text-color);
    text-decoration: underline;
}

.CategoryItemInnerContainerChosen p {
    color: var(--category-chosen);
}

.CategoryItemSubCategoriesContainer {
    padding-right: 30px;
}


/* To Delete */
.CategoryItemAdminPlus {
    cursor: pointer;
}

.CategoryItemAdminPlus:hover {
    color: grey;
}

.CategoryItemAdminMinus {
    color: red;
}

.CategoryItemAdminMinus:hover {
    color: rgb(255, 131, 131);
}
