.ProductOuterBackgroundContainer {
    width: 274px;
    height: 334px;
    background: linear-gradient(180deg, #dfe1f8, #e5bcbc);
    border-radius: 6px;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        width: 44.4vw; 
        height: auto; 
    }
}

.ProductOuterContainer {
    width: 99%;
    height: 99%;
    background-color: rgb(248, 243, 239);
    border: 0.2px solid rgba(249, 23, 23, 0.3);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ProductContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.ProductImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ProductImage {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 200px;
    @media (max-width: 768px) {
        width: 32vw;  
        height: 32vw;
        padding-top: 10px;
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.ProductImageArrows {
    cursor: pointer;
}

.ProductImageArrows:hover {
    color:rgb(71, 70, 70);
}

.ProductImageArrows:active {
    color:rgb(117, 117, 117);
}

.ProductTitle {
    align-items: center;
    display: flex;
    justify-content: center;
}

.ProductTitle span {
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: 600;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 270px;

    @media (max-width: 768px) {
        font-size: 14px;
        width: 40vw;
    }
}

.ProductDescription {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 5px;

    @media (max-width: 768px) {
        margin-top: 0px;
    }
}

.ProductDescription span {
    font-size: 14px;
    font-weight: 400;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 11px;
    }
}

.ProductDescriptionText {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    width: 200px;
    direction: rtl;
    text-align: center;
    flex: 2;
    height: 18px;
    @media (max-width: 768px) {
        width: 25vw;
    }
}

.ProductDescriptionReadMore{
    direction: rtl;
    text-align: right;
    flex: 1;
    white-space:nowrap;
    display: flex;
    text-decoration: underline;
    cursor: pointer;
}

.ProductPrice {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 768px) {
        margin-top: 4px;
    }
}

.ProductPrice span {
    font-size: 18px;
    font-weight: 400;

    @media (max-width: 768px) {
        font-size: 11px;
    }
}

.ProductButtonsContainer {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    justify-content: flex-end;
    @media (max-width: 768px) {
        margin-top: 10px;
    }
}

.ProductBuyNowButtonContainer {
    
    height: 30px;

    @media (max-width: 768px) {
        width: 90px;
    }
}

.ProductStoreRibbonContainer {
    display: flex;
    flex-direction: column;
    width: 40px;
    position: absolute;
    left: 2px;
    top: 10px;
}

.ProductStoreRibbonContainer span {
    font-size: 10px;
}

.ProductStoreRibbonImageContainer {
    display: flex;
    justify-content: flex-start;
}

.ProductStoreRibbonImageContainer img {
    max-width: 100%;
    max-height: 30px;
}

.ProductDivClickable {
    cursor: pointer;
}